<template>
    <div class="box-card" style="text-align: left">
      <el-form :model="addForm" ref="addFormRefs" :rules="addFormRules" label-width="140px" class="addForm">
        <el-form-item label="APP功能包名称:" prop="pack_name">
          <el-input placeholder="请输入APP功能包名称" autocomplete="off" maxlength="15" :value="addForm.pack_name" @input="(e) => (addForm.pack_name = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item label="APP类型:">
          <el-select v-model="addForm.app_type" placeholder="请选择APP类型">
            <!-- <el-option label="用户端" :value="1"></el-option> -->
            <el-option v-for="item in getOptions('PLPlateType')" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="配置APP功能:" prop="children">
          <config ref="configRef" :list="tableData"></config>
        </el-form-item> -->
        <el-form-item label="配置APP功能:">
          <config ref="configRef" :list="tableData"></config>
        </el-form-item>
      </el-form>
      <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="onSubmit()" v-preventReClick>保 存</lbButton>
    </div>
  </template>
<script>
import systemapi from '@/api/systemapi'
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import config from '../../config.vue'
export default {
  components: {
    config
  },
  props: ['id'],
  data () {
    return {
      addForm: {
        pack_name: '', // 功能包名称
        app_type: 1, // app类型
        children: [] // app模板数据
      },
      addFormRules: {
        pack_name: [
          { required: true, message: '请输入菜单包名称', trigger: 'blur' }
        ]
        // children: [
        //   // { type: 'array', required: true, message: '请配置APP功能', trigger: 'change' }

        // ]
      },
      tableData: [],
      spanArr: [], // 用于存放需要合并的行的个数
      spanIndex: 0 // 记录spanArr数组的下标
    }
  },
  created () {
    if (this.id) {
      this.fnGetAppPlatedetail()
    } else {
      this.fngetAppPlate()
    }
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 获取详情
    async fnGetAppPlatedetail () {
      const res = await systemapi.getAppPlatedetail({
        _id: this.id
      })
      if (res && res.Code === 200) {
        this.addForm.pack_name = res.Data.pack_name
        this.addForm.app_type = res.Data.app_type
        this.tableData = res.Data.children
      }
    },
    // 获取app模板列表
    async fngetAppPlate () {
      const res = await systemapi.getResourceAppPlatelist({
        app_type: 1
      })
      if (res && res.Code === 200) {
        this.tableData = res.Data.children
        // res.Data.children.forEach((d) => {
        //   d.children.forEach((i) => {
        //     this.tableData.push({
        //       plate_name: d.plate_name,
        //       is_theme: d.is_theme,
        //       plate_type: d.plate_type,
        //       plate_sort: d.plate_sort,
        //       plate_id: i.plate_id,
        //       childrenplate_name: i.plate_name,
        //       show_name: i.show_name,
        //       able_personnel: i.able_personnel,
        //       childrenis_theme: i.is_theme,
        //       big_icon: i.big_icon,
        //       small_icon: i.small_icon,
        //       is_open: i.is_open,
        //       childrenplate_sort: i.plate_sort,
        //     });
        //   });
        // });
        // this.getSpanArr(this.tableData);
      }
      console.log(this.tableData)
    },
    // 返回
    defaultForm () {
      this.setQueryStatus(2)
      this.$emit('closeDialog', false)
    },
    // 保存提交
    onSubmit () {
      var newArr = this.$refs.configRef.Gettabledata()
      this.addForm.children = newArr
      this.$refs.addFormRefs.validate((valid) => {
        if (valid) {
          if (this.id) {
            this.fnUptSysResourceAppPlate()
          } else {
            this.fnAddSysResourceAppPlate()
          }
        } else {
          return false
        }
      })
    },
    // 新增
    async fnAddSysResourceAppPlate () {
      const res = await systemapi.addSysResourceAppPlate(this.addForm)
      if (res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    // 修改
    async fnUptSysResourceAppPlate () {
      var obj = this.addForm
      obj._id = this.id
      const res = await systemapi.uptSysResourceAppPlate(obj)
      if (res.Code === 200) {
        this.defaultForm()
      }
    }
  }
}
</script>
  <style scoped lang="less">
  .addForm {
    /deep/ .el-select,
    /deep/.el-input {
      width: 400px;
      margin-right: 10px;
    }
  }
  .box-card {
  position: relative;
  padding-bottom: 30px;
  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }

}
  </style>
