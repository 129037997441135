<template>
  <!-- APP自定义 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="APP功能包：">
        <el-input maxlength="15" @keyup.enter.native="onSubmit" placeholder="请输入APP功能包" :value="queryForm.pack_name"
          @input="(e) => (queryForm.pack_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="APP自定义">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="batchDel">删除</lbButton>
          <lbButton icon="xinzeng" @click="goEditPage('')">新增</lbButton>
        </div>
      </template>
      <!-- 表格 -->
      <!-- 使用插件生产表格 -->
      <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
        @size-change="sizeChange" @current-change="currentChange">
        <template slot-scope="{row}" slot="app_type">
          <span>{{ getWayName("PLPlateType", row.app_type) }} </span>
        </template>
        <template slot-scope="{row}" slot="create_time">
          <span>{{ validDateTime(row.create_time) }}</span>
        </template>
        <template slot-scope="{row}" slot="menu">
          <lbButton type="warning" icon="bianji" hint="编辑" @click="goEditPage(row._id)"></lbButton>
        </template>
      </avue-crud> -->
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border stripe
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
        <el-table-column prop="pack_name" label="APP功能包" min-width="220"></el-table-column>
        <el-table-column prop="app_type" label="APP类型">
          <template slot-scope="scope">
            <span>{{ getWayName("PLPlateType", scope.row.app_type) }} </span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            <span>{{ validDateTime(scope.row.create_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <lbButton type="warning" icon="bianji" hint="编辑" @click="goEditPage(scope.row._id)"></lbButton>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="TotalCount"></el-pagination>
    </le-card>
    <!-- 新增编辑自定义APP -->
    <lebo-dialog :title="dialogAppCustomId ? '编辑自定义APP' : '新增自定义APP'" :isShow="showAppCustom" width="70%" top="5vh"
      @close="showAppCustom = false" footerSlot>
      <addAppCustom v-if="showAppCustom" :id="dialogAppCustomId" @closeDialog="closeAppCustom">
      </addAppCustom>
    </lebo-dialog>
  </div>
</template>
<script>
import systemApi from '@/api/systemapi'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import addAppCustom from '@/components/appCustom/components/addAppCustom/index.vue'
export default {
  components: { addAppCustom },
  data () {
    return {
      // 查询表单
      queryForm: {
        pack_name: '',
        app_type: 0,
        PageSize: 10,
        PageIndex: 1
      },
      // 表格数据
      tableData: [],
      TotalCount: 0,
      // 选中的表格数据
      multipleSelection: [],
      showAppCustom: false,
      dialogAppCustomId: '',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: 'APP功能包',
            prop: 'pack_name'
          }, {
            label: 'APP类型',
            prop: 'app_type',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetAppPlatepagelist()
  },
  mounted () {
    window.addEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    async fnGetAppPlatepagelist () {
      const res = await systemApi.getAppPlatepagelist(this.queryForm)
      if (res && res.Code === 200) {
        this.tableData = res.Data.DataList
        this.TotalCount = res.Data.TotalCount
      } else {
        this.tableData = []
        this.TotalCount = 0
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetAppPlatepagelist()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.fnGetAppPlatepagelist()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetAppPlatepagelist()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.fnGetAppPlatepagelist()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetAppPlatepagelist()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (data) {
      this.multipleSelection = data
    },
    // selectionChange (array) {
    //   this.multipleSelection = array
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的APP功能包, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            var arr = []
            this.multipleSelection.forEach((item) => {
              arr.push(item._id)
            })
            this.fnDelAppPlate({ _id: arr.join(',') })
          })
          .catch(() => {
            this.$msg.info('已取消删除')
          })
      } else {
        this.$msg.warning('请选择要删除的APP功能包！')
      }
    },
    closeAppCustom () {
      this.showAppCustom = false
      this.fnGetAppPlatepagelist()
    },
    async fnDelAppPlate (obj) {
      const res = await systemApi.delAppPlate(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetAppPlatepagelist()
    },
    goEditPage (id) {
      this.dialogAppCustomId = id
      this.showAppCustom = true
    }
  }
}
</script>
<style scoped lang="less"></style>
